import React from "react";

const ConfirmPopup = (props) => {
    return (
        <div className='popup-view'>
          <div className="popup-wrap" >
            <div className='popup-container'>
              <p>{props.message}</p>
              <button className='btn-style green' onClick={props.clickConfirm}>Ja</button>
              <button className='btn-style red' onClick={props.clickCancel}>Nein</button>
            </div>
          </div>
        </div>
    )
}

export default ConfirmPopup;