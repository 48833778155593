import React from 'react';
import { NavLink } from "react-router-dom";
import { FaSearch, FaHome, FaUsers } from 'react-icons/fa';

const SideDrawer = (props) => {

	return (
		<nav className={`sidebar_container ${props.show ?  "open" :  ""}`}>
			<div className='sidebar_list' >
				<NavLink id='home' exact  to='/'
					className="sidebar_item"
					activeClassName="selected_tab"
					title="Home"
				>
					<FaHome/>
				</NavLink>
				<NavLink id='search' exact to='/search'
					className="sidebar_item"
					activeClassName="selected_tab"
					title="Search"
				>
					<FaSearch/>
				</NavLink>
				<NavLink id='clients' exact to='/clients'
					className="sidebar_item"
					activeClassName="selected_tab"
					title="Clients"
				>
					<FaUsers/>
				</NavLink>
			</div>
		</nav>
	)
}

export default SideDrawer;