import React, { useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer";
// import Swipe from "react-easy-swipe";
import SideDrawer from "./../components/SideDrawer";
import { Route, Switch, withRouter } from "react-router-dom";
import UsersPanel from "../components/usersPanel/Users";
import HomePanel from "../components/homePanel/Home";

const Home = () => {
  const [sideDrawerOpen, setSideDrawer] = useState(true)

  // const onSwipeLeft = (e) => {
  //   setSideDrawer(true)
  // }

  // const onSwipeRight = (e) => {
  //   setSideDrawer(false)
  // }
  return (
    <div className="home-content">
      <Header
        sideDrawerClickHandler={() => setSideDrawer(!sideDrawerOpen)}
      />
      <div className="main-content">
        <SideDrawer show={sideDrawerOpen} 
          // defaultTab={this.props.location.pathname.replace('/', '')} 
        />
        <Switch>
          <Route exact path="/">
            <HomePanel />
          </Route>
          <Route exact path="/clients">
            <UsersPanel />
          </Route>
        </Switch>
      </div>
      <Footer/>
    </div>
  );
};

export default withRouter(Home);