import React from "react";
import LoginForm from "../components/LoginForm";
import LogoImg from "./../assets/images/logo.png";

const Login = () => {
  return (
    <div className="login-content">
      <div className="container login-header">
        <img src={LogoImg} alt="logo"/>
      </div>
      <div className="container">
        <div className="login-content">
          <p className="wellcome-msg">Willkommen im Bonebridge Admin Panel. <br/>Bitte einloggen zum Fortfahren.</p>
          <LoginForm/>
        </div>
      </div>
    </div>
  );
};

export default Login;