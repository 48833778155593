import React, { useState, useEffect } from 'react';
import ClientForm from './ClientForm';
import API from "./../../API";
import ConfirmPopup from "./../ConfirmationPopup";

const UsersPanel = () => {
  const [clientFormVisible, setClientFormVisibility] = useState(false);
  const [allClients, setClients] = useState([]);
  const [forwardedClient, setForwardedClient] = useState({});
  const [popupVisible, setPopup] = useState(false);
  const [clientID, setClientID] = useState(null)

  useEffect(() => {
    getClients()
  }, []);

  const getClients = () => {
    API.getClients()
    .then(res => {
      setClients(res.data.clients.reverse())
    })
    .catch(error => {
      console.log(error)
    })
  }

  const newClientHandler = () => {
    setClientFormVisibility(true)
    setForwardedClient({})
  }

  const clientHandler = (clientID) => {
    API.getSingleClient(clientID)
    .then(res => {
      setForwardedClient(res.data.client)
      setClientFormVisibility(true)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const refreshClientHandler = (editedClient) => {
    let tempClients = []
    allClients.forEach(client => {
        if (client.id === editedClient.id) {
            tempClients.push(editedClient)
        } else {
            tempClients.push(client)
        }
    })
    setClients([])
    setClients(tempClients)
    setClientFormVisibility(false)
} 

const deleteClient = () => {
  setPopup(false)
  API.deleteClient(clientID)
  .then(res => {
    refreshClientHandler(res.data.client)
  })
  .catch(error => {
    console.log(error)
    alert("Etwas ist schief gelaufen. Der Client wurde nicht gelöscht.")
  })
}

	return (
    <div className='container'>
      <div className="users-panel">
        {!clientFormVisible ? 
          <React.Fragment>
            <div className="add-client-content">
              <p>BONEBRIDGE KUNDEN</p>
              <button className="btn-style" onClick={newClientHandler}>Kunden hinzufügen (+)</button>
            </div>
            {allClients && <div className="table-wrapper">
              <table>
                <tbody>
                {allClients.map((client, index) => {
                    return (
                      <tr key={index} className={client.deleted ? "deleted" : ""}>
                        <td>
                          <div className="table-cell">
                            <p>{client.name}</p>
                            <span>{client.address}{client.city && ", " + client.city}{client.country_code && ", " + client.country_code}</span>
                          </div>
                        </td>
                        <td>
                          <div className="table-cell with-btns">
                            <button className="btn-style" 
                              onClick={() => {
                                setClientID(client.id);
                                setPopup(true)
                              }}
                              disabled={client.deleted}
                            >
                              Deaktivieren
                            </button>
                            <button className="btn-style" onClick={() => clientHandler(client.id)} disabled={client.deleted}>Einzelheiten</button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>}
          </React.Fragment>
          : 
          <ClientForm client={forwardedClient} refreshClients={refreshClientHandler} newClient={getClients} closeForm={() => setClientFormVisibility(false)}/>
        }
      </div>
      {popupVisible ? <ConfirmPopup message="Möchten Sie diesen Client wirklich löschen?" clickConfirm={deleteClient} clickCancel={() => setPopup(false)}/> : null}
    </div>
	)
}

export default UsersPanel;