import React, { useState } from 'react';
import API from "./../../API";

const EditUser = (props) => {
  const [editUser, setEditUser] = useState({
    first_name: props.userToEdit.first_name ? props.userToEdit.first_name : "",
    last_name: props.userToEdit.last_name ? props.userToEdit.last_name : "",
    phone: props.userToEdit.phone ? props.userToEdit.phone : "",
    email: props.userToEdit.email ? props.userToEdit.email : "",
    salutation: props.userToEdit.salutation ? props.userToEdit.salutation : "mrs"
  });
  const [validMsgEditUser, setValidMsgEditUser] = useState("");

  const onChangeEditUser = (e) => {
    setEditUser({
      ...editUser,
      [e.target.id]: e.target.value,
    });
  }

  const editUserHandler = () => {
    if(!editUser.first_name || !editUser.last_name || !editUser.email) {
      setValidMsgEditUser("Alle mit einem Sternchen gekennzeichneten Felder müssen ausgefüllt werden")
      return
    }
    if(editUser.email === props.userToEdit.email) {
      delete editUser['email']
    }
    setValidMsgEditUser("")
    API.editUser(props.userToEdit.id, editUser)
    .then(res => {
      props.close()
      const edited = res.data.client.users.find(user => user.id === props.userToEdit.id)
      props.refreshUsers(edited)

    })
    .catch(error => {
      console.log(error)
      if(error === "email_used") {
        setValidMsgEditUser("Diese E-Mail wurde bereits einem anderen Benutzer zugewiesen.")
      } else {
        alert(error)
      }
    })
  }
 return <div className='popup-view'>
          <div className="popup-wrap" >
            <div className='popup-container edit-user'>
              <div className="form-fragment">
                <div className="title-wrapper">
                  <h3 className="fragment-title">Benutzer</h3>
                </div>
                <div className="fragment-row">
                  <select id="salutation" value={editUser.salutation} onChange={onChangeEditUser}>
                    <option value="mrs">Frau</option>
                    <option value="mr">Herr</option>
                  </select>
                </div>
                <div className="fragment-row">
                  <input id="first_name" type="text" placeholder="Vorname*" value={editUser.first_name} onChange={onChangeEditUser}/>
                  <input id="last_name" type="text" placeholder="Name*"  value={editUser.last_name}  onChange={onChangeEditUser}/>
                </div>
                <div className="fragment-row">
                  <input id="email" type="email" placeholder="Email*" value={editUser.email} onChange={onChangeEditUser}/>
                  <input id="phone" type="phone" placeholder="Telefonnummer" value={editUser.phone} onChange={onChangeEditUser}/>
                </div>
                <div className="fragment-row"><span className="wrn-massage">{validMsgEditUser}</span></div>
                <div className="row-align-right">
                  <button className="btn-style" onClick={props.close}>Abbrechen</button>
                  <button className="btn-style" onClick={editUserHandler}>Bearbeiten</button>
                </div>
              </div>
            </div>
          </div>
        </div>
 
};

export default EditUser;
