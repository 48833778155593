import React from 'react';

export default ({ title = '', value = "", children }) => {
  
  return (
    <div className="data-box">
      <div className="box-details">
        <span className="title">{title}</span>
        <span>{value}</span>
      </div>
      {children}
    </div>
  );
};
