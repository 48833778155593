import React from 'react';
 
const Footer = () => {

  return (
    <footer>
      <div className="footer-content">
        <span>© Bonebridge AG</span> 
        <span>|</span>
        <span>Bahnhofstrasse 11, 6300 Zug, Schweiz</span>
      </div>
    </footer>
  )
}

export default Footer;