import React, { useState, useEffect } from "react";
import API from "./../../API";
import ConfirmPopup from "./../ConfirmationPopup";
import EditUser from "./editUser";
import { FaEdit, FaTrash, FaEnvelope } from 'react-icons/fa';
import { formatDate } from './../../helpers/formatDate';


const ClientForm = (props) => {
  const boneProducts = [
    {product_id:1, name: "SALGINA", available: false,  konsilager: false},
    {product_id:2, name: "CASCELLA", available: false, konsilager: false},
    {product_id:4, name: "TAMINA", available: false, konsilager: false},
    {product_id:3, name: "TRIFT", available: false, konsilager: false},
  ];
  const [allProducts, setAllProducts] = useState( () => {
    if(props.client.id) {
      boneProducts.forEach(product => {
        const foundProd = props.client.clientProducts.find(p => p.product_id === product.product_id)
        if(foundProd) {
          product["available"] = true
          product["konsilager"] = foundProd.order_type === 'storage' ? true : false
        } else {
          product["available"] = false
        }
      })
      return boneProducts
    } else {
      return boneProducts
    }
  }

  );
  const [client, setClient] = useState({
    id: props.client.id ? props.client.id : "",
    name: props.client.name ? props.client.name : "",
    address: props.client.address ? props.client.address : "",
    city: props.client.city ? props.client.city : "",
    country_code: props.client.country_code ?  props.client.country_code : "",
    zip: props.client.zip ? props.client.zip : "",
    section: props.client.section ? props.client.section : "",
    same_invoice_address: props.client.id ? props.client.same_invoice_address : false,
    invoice_address: props.client.invoice_address && {
      address: props.client.invoice_address.address,
      city: props.client.invoice_address.city,
      country_code: props.client.invoice_address.country_code,
      name: props.client.invoice_address.name,
      section: props.client.invoice_address.section,
      zip: props.client.invoice_address.zip
    }, 
    pricing_level: props.client.pricing_level ? props.client.pricing_level : "price"
  });
  const [validMsgClient, setValidMsgClient] = useState("");
  const [newUser, setNewUser] = useState({
    client_id: client.id,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    salutation: "mrs"
  })
  const [validMsgNewUser, setValidMsgNewUser] = useState("");
  const [allUsers, setAllUsers] = useState(
    props.client.users && props.client.users.map((user) => {
      return user
    })
  );
  const [popupVisible, setPopup] = useState(false);
  const [msgToConfirm, setMsgToConfirm] = useState("");
  const [actionType, setActionType] = useState(null);
  const [userID, setUserID] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [editUserForm, setEditUserForm] = useState(false);
  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


 useEffect(() => {
  popupVisible || editUserForm
    ? setTimeout(() => {
      (document.querySelector("html").style.overflow = "hidden")
    }, 200)
    : (document.querySelector("html").style.overflow = "auto");
}, [popupVisible, editUserForm]);

  const handleChange = (e) => {
    if(e.target.id === "same_invoice_address") {
      setInvoiceAddressHandler()
      setClient({
        ...client,
        [e.target.id]: e.target.checked,
      });
      return
    } else {
        setClient({
          ...client,
          [e.target.id]: e.target.value,
        });
    }
  };

  const handleChangeInvoiceDetails = (e) => {
    setClient({
      ...client,
      invoice_address: {
        ...client.invoice_address,
        [e.target.name]: e.target.value,
      }
    });
  };

  const setInvoiceAddressHandler = () => {
    if(client.same_invoice_address) {
      setClient({
        ...client,
        invoice_address: {
          ...client.invoice_address,
          address: client.address,
          city: client.city,
          country_code: client.country_code,
          name: client.name,
          section: client.section,
          zip: client.zip
        }
      });
      return
    } else {
      if(props.client.invoice_address) {
        setClient({
          ...client,
          invoice_address: {
            ...client.invoice_address,
            address: props.client.invoice_address.address,
            city: props.client.invoice_address.city,
            country_code: props.client.invoice_address.country_code,
            name: props.client.invoice_address.name,
            section: props.client.invoice_address.section,
            zip: props.client.invoice_address.zip
          }
        });
      } else {
        setClient({
          ...client,
          invoice_address: {
            ...client.invoice_address,
            address: "",
            city: "",
            country_code: "",
            name: "",
            section: "",
            zip: ""
          }
        });
      }
    }
  }

  const handleProductChange = (e) => {
    const tempProducts = allProducts;
      tempProducts.forEach(product => {
        if(product.product_id === parseInt(e.target.id)) {
          product["available"] = e.target.checked
          if(!e.target.checked) {
            product["konsilager"] = false
          }
        } else if(product.name === e.target.name) {
          product["konsilager"] = e.target.checked
        }
      })
      setAllProducts([...tempProducts])
  }

  const confirmChanges = () => {
    if(!client.id || !client.name || !client.address || !client.zip || !client.city || !client.country_code) {
      setValidMsgClient("Alle mit einem Sternchen gekennzeichneten Felder im Kundenformular müssen ausgefüllt werden.")
      return
    }
    setMsgToConfirm(props.client.id ? "Wollen Sie die Änderungen speichern?" : "Möchten Sie einen neuen Client speichern?")
    setActionType("client-update")
    setPopup(true)
    setValidMsgClient("")
  }

  const clientHandler = () => {
    setPopup(false);
    if(client.same_invoice_address) {
      delete client.invoice_address
    }
    const products = []
    allProducts.forEach(product => {
      if (product.available && product.konsilager) {
        products.push({product_id: product.product_id, order_type: "storage"})
      } else if (product.available && !product.konsilager) {
        products.push({product_id: product.product_id, order_type: "direct"})
      }
    })
    if(products.length) {
      client["products"] = products
    }
    if(!props.client.id) {
      client["advisor"] = "Christof Gerber";

      API.createClient(client)
      .then(res => {
        props.newClient()
        props.closeForm()
        window.scrollTo(0, 0)
      })
      .catch(error => {
        console.log(error)
        if(error === "id_used") {
          setValidMsgClient ("Diese Client-ID wird bereits verwendet.")
        } else {
          alert(error)
        }
      })
    } else if (props.client.id) {
      API.editClient(client.id, client)
      .then(res => {
        props.refreshClients(res.data.client)
        window.scrollTo(0, 0)
      })
      .catch(error => {
        console.log(error)
        alert(error)
      })
    }
  }

  const onChangeNewUser = (e) => {
    setNewUser({
      ...newUser,
      [e.target.id]: e.target.value,
    });
  }

  const createNewUser = () => {
    if(!newUser.first_name || !newUser.last_name || !newUser.email) {
      setValidMsgNewUser("Alle mit einem Sternchen gekennzeichneten Felder müssen ausgefüllt werden.")
      return
    }
    setBtnDisabled(true)
    setValidMsgNewUser("")
    API.createUser(newUser)
    .then(res => {
      let users = allUsers;
      users.push(res.data.client.users[res.data.client.users.length - 1])
      setAllUsers([...users])
      setNewUser({
        client_id: client.id,
        first_name: "",
        last_name: "",
        phone: "",
        email: ""})
        setBtnDisabled(false)
    })
    .catch(error => {
      console.log(error)
      setBtnDisabled(false)
      if(error === "email_used") {
        setValidMsgNewUser("Diese E-Mail wurde bereits einem anderen Benutzer zugewiesen.")
      } else {
        alert(error)
      }
    })
  }

  const forwardUserToEdit = (user) => {
    setEditUser(user)
    setEditUserForm(true)
  }


  const refreshUserHandler = (edited) => {
    let tempUsers = []
    allUsers.forEach(user => {
        if (user.id === edited.id) {
            tempUsers.push(edited)
        } else {
            tempUsers.push(user)
        }
    })
    setAllUsers([])
    setAllUsers(tempUsers)
}

const deleteUserHandler = () => {
  setPopup(false)
  API.deleteUser(userID)
  .then(res => {
    refreshUserHandler(res.data.user)
  })
  .catch(error => {
    console.log(error)
    alert("Etwas ist schief gelaufen. Der Benutzer wurde nicht gelöscht.")
  })
}

const sendEmailHandler = () => {
  setPopup(false)
  API.sendEmail(userID)
  .then(res => {
    refreshUserHandler(res.data.user)
  })
  .catch(error => {
    console.log(error)
    alert("Etwas ist schief gelaufen. Die E-Mail wurde nicht gesendet.")
  })
}

const confirmationHandler = () => {
  if (actionType === "client-update") {
    clientHandler()
  } else if (actionType === "delete-user") {
    deleteUserHandler()
  } else if (actionType === "send-email") {
    sendEmailHandler()
  }
}

  return (
    <div>
      <div className="form-fragment">
        <div className="title-wrapper">
          <h3 className="fragment-title">{props.client.id ? "Kunde bearbeiten" : "Neuer Kunde"}</h3>
        </div>
        <div className="fragment-row">
          <input id="id" placeholder="Kundennummer*" value={client.id} onChange={handleChange} disabled={props.client.id}/>
        </div>
        <div className="fragment-row">
          <input id="name" placeholder="Name*" value={client.name} onChange={handleChange}/>
          <input id="section" placeholder="Zusatz" value={client.section} onChange={handleChange}/>
        </div>
        <div className="fragment-row">
          <input id="address" placeholder="Strasse und Nummer*" value={client.address} onChange={handleChange}/>
        </div>
        <div className="fragment-row">
          <input id="zip" placeholder="PLZ*" value={client.zip} onChange={handleChange}/>
          <input id='city' placeholder="Ort*" value={client.city} onChange={handleChange}/>
        </div>
        <div className="fragment-row">
          <input id="country_code" placeholder="Land*" value={client.country_code} onChange={handleChange}/>
        </div>
      </div>
      <div className="form-fragment">
        <div className="title-wrapper">
          <h3 className="fragment-title">Rechnungsadresse</h3>
          <label className="checkbox">
            <input id="same_invoice_address" type="checkbox" checked={client.same_invoice_address} onChange={handleChange}/>
            <span>Entspricht der Kundenadresse</span>
          </label>
        </div>
        <div className="fragment-row">
          <input name="name" placeholder="Name" value={client.same_invoice_address ? client.name : (client.invoice_address ? client.invoice_address.name : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
          <input name="section" placeholder="Zusatz" value={client.same_invoice_address ? client.section : (client.invoice_address ? client.invoice_address.section : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
        </div>
        <div className="fragment-row">
          <input name="address" placeholder="Strasse und Nummer" value={client.same_invoice_address ? client.address : (client.invoice_address ? client.invoice_address.address : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
        </div>
        <div className="fragment-row">
          <input name="zip" placeholder="PLZ" value={client.same_invoice_address ? client.zip : (client.invoice_address ? client.invoice_address.zip : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
          <input name="city" placeholder="Ort" value={client.same_invoice_address ? client.city : (client.invoice_address ? client.invoice_address.city : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
        </div>
        <div className="fragment-row">
          <input name="country_code" placeholder="Land" value={client.same_invoice_address ? client.country_code : (client.invoice_address ? client.invoice_address.country_code : "")}
            disabled={client.same_invoice_address}
            onChange={handleChangeInvoiceDetails}/>
        </div>
      </div>
      <div className="form-fragment">
        <div className="title-wrapper">
          <h3 className="fragment-title">Produkte</h3>
        </div>
        <div className="fragment-row">
          <select id="pricing_level" value={client.pricing_level} onChange={handleChange}>
            <option value="price">Default Price</option>
            <option value="price_a">Price A</option>
            <option value="price_b">Price B</option>
            <option value="price_c">Price C</option>
          </select>
        </div>
        {allProducts.map((product, i) => {
          return <div key={i} className="checkbox-row">
                  <label className="checkbox">
                    <input id={product.product_id} type="checkbox" defaultChecked={product.available} onChange={handleProductChange}/>
                    <span>{product.name}</span>
                  </label>
                  {product.available ? <label className="checkbox">
                    <input name={product.name} type="checkbox" defaultChecked={product.konsilager} disabled={!product.available}  onChange={handleProductChange}/>
                    <span>KONSILAGER</span>
                  </label> : null}
                </div>
          })}
      </div>
      {allUsers && <div className="form-fragment">
        <div className="title-wrapper">
          <h3 className="fragment-title">Benutzer</h3>
        </div>
        <div className="fragment-row">
          <select id="salutation" onChange={onChangeNewUser}>
            <option value="mrs">Frau</option>
            <option value="mr">Herr</option>
          </select>
        </div>
        <div className="fragment-row">
          <input id="first_name" type="text" placeholder="Vorname*" value={newUser.first_name}  onChange={onChangeNewUser}/>
          <input id="last_name" type="text" placeholder="Name*" value={newUser.last_name}  onChange={onChangeNewUser}/>
        </div>
        <div className="fragment-row">
          <input id="email" type="email" placeholder="Email*" value={newUser.email} onChange={onChangeNewUser}/>
          <input id="phone" type="phone" placeholder="Telefonnummer" value={newUser.phone} onChange={onChangeNewUser}/>
        </div>
        <div className="fragment-row"><span className="wrn-massage">{validMsgNewUser}</span></div>
        <div className="row-align-right">
          {/* <label className="checkbox">
            <input type="checkbox"/>
            <span>Senden Sie einen E-Mail-Link an Benutzer</span>
          </label> */}
          <button className="btn-style" onClick={createNewUser} disabled={btnDisabled}>Erstellen</button>
        </div>
      </div>}
      {allUsers && <div className="form-fragment">
        <div className="title-wrapper with-segments">
          <p className="fragment-title" style={{maxWidth: 150}}>Name</p>
          <p className="fragment-title">Email</p>
          <p className="fragment-title">Telefon</p>
          <p className="fragment-title">Benachrichtigt</p>
          <p className="fragment-title" style={{minWidth: 150}}></p>
        </div>
        {allUsers.length !==0 ? allUsers.map((user, index) => {
          return  <div key={index} className={`fragment-row with-segments ${user.deleted ? "deleted" : ""}`}>
                    <p className="data-cell" style={{maxWidth: 150}}>{user.first_name.charAt(0)}. {user.last_name}</p>
                    <p className="data-cell">{user.email}</p>
                    <p className="data-cell">{user.phone}</p>
                    <p className="data-cell">{user.reset_password ? (user.reset_password.updated_at ? formatDate(new Date(user.reset_password.updated_at)) : formatDate(new Date(user.reset_password.created_at)) ) : "/"}</p>
                    <div className="data-cell" style={{minWidth: 150}}>
                      <button className="btn-style"
                        onClick={()=>forwardUserToEdit(user)}
                        title="Benutzer bearbeiten"
                        disabled={user.deleted}
                        >
                        <FaEdit/>
                      </button>
                      <button className="btn-style"
                        onClick={()=>{
                          setMsgToConfirm('Möchten Sie diesem Benutzer wirklich eine E-Mail mit dem Titel "Bonebridge-Passwort zurücksetzen" senden?')
                          setActionType("send-email")
                          setPopup(true)
                          setUserID(user.id)
                        }}
                        title="E-Mail senden"
                        disabled={user.deleted}
                      >
                        <FaEnvelope/>
                      </button>
                      <button className="btn-style"
                        onClick={()=>{
                          setMsgToConfirm('Möchten Sie diesen Benutzer wirklich löschen?')
                          setActionType("delete-user")
                          setPopup(true)
                          setUserID(user.id)
                        }}
                        title="Benutzer löschen"
                        disabled={user.deleted}
                      >
                        <FaTrash/>
                      </button>
                    </div>
                  </div>
        }) : <div className="fragment-row center">Dieser Client hat noch keine Benutzer!</div>}

      </div>}
      <div className="fragment-row"><span className="wrn-massage">{validMsgClient}</span></div>
      <div className="row-align-right">
        <button className="btn-style" onClick={props.closeForm}>Abbrechen</button>
        <button className="btn-style" onClick={confirmChanges}>Speichern</button>
      </div>
      {popupVisible ? <ConfirmPopup message={msgToConfirm} clickConfirm={confirmationHandler} clickCancel={() => setPopup(false)}/> : null}
      {editUserForm ? <EditUser userToEdit={editUser} close={() => setEditUserForm(false)} refreshUsers={refreshUserHandler}/> : null}
    </div>
  );
};

export default ClientForm;