import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Loader from "./../assets/images/loader.gif";
import API from "./../API";


const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  const [warningMsg, setWarningMsg] = useState("")
  const { register, handleSubmit, errors } = useForm({criteriaMode: 'all'});

  const handleChange = (e) => {
    setWarningMsg("")
    setLoginData({
      ...loginData,
      [e.target.id]: e.target.value,
    });
  };
  const onSubmitHandler = async () => {
    setLoading(true);
    setWarningMsg("")
    await API.login(loginData)
    .then(() => {
      setLoading(false);
      window.location.pathname = '/';
    })
    .catch(error => {
      setLoading(false);
      if(error === "invalid_credentials") {
        setWarningMsg("Benutzername oder Passwort falsch.")
      } else {
        setWarningMsg("Etwas ist schief gelaufen.")
      }
    })
  };

return (
  <form className="login-form" onSubmit={handleSubmit(onSubmitHandler)}>
    <div className="input-holder">
      <input
        type="text"
        id="username"
        placeholder="Nutzername"
        onChange={handleChange}
        name="username"
        autoComplete="off"
        ref={register({
          required: "Erforderlich"
        })}
        className={errors.username ? "error" : ""}
      />
      <span className="input-error">
        <ErrorMessage
          errors={errors}
          name="username"
          render={({ message }) => <p>{message}</p>}
        />
      </span>
    </div>
    <div className="input-holder">
      <input
        type="password"
        id="password"
        placeholder="Passwort"
        onChange={handleChange}
        name="password"
        autoComplete="off"
        ref={register({
          required: "Erforderlich",
          // pattern: /@".{6,}"/
          minLength: {
            value: 6,
            message: 'Mindestens 6 Zeichen',
          },
        })}
        className={errors.password ? "error" : ""}
      />
      <span className="input-error">
        <ErrorMessage
          errors={errors}
          name="password"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p key={type}>{message}</p>
            ))
          }
        />
      </span>
    </div>
    <div><span className="wrn-massage">{warningMsg}</span></div>
    <button type="submit" className={`btn-style ${loading ? "loading" : ""}`}>
      {loading ? <img src={Loader} alt="Loader" /> : `Anmeldung`}{" "}
      {!loading && <span></span>}
    </button>
  </form>
)
};

export default LoginForm;
