import React from 'react';
// import LogoImg from "./../../assets/images/logo.png";
import ButtonToggleSideDrawer from './ButtonToggleSideDrawer';
import { FaSignOutAlt } from 'react-icons/fa';
import API from "./../../API";
 
const Header = (props) => {

  const logOutHandler = async () => {
    try {
      await API.logOut();
      window.location.replace(`/login`);
    } catch (error) {
      console.log(error)
      alert(error)
    }
  }

  return (
    <header className='header'>
      <div className="container">
        <nav className='header_content'>
            <div className="header-left">
              <ButtonToggleSideDrawer click={props.sideDrawerClickHandler}/>
            </div>
            {/* <div className='spacer'/> */}
            <div className='header-right'>
              {/* <img src={LogoImg} alt='logo'></img> */}
              {/* <div className="navigation_title">{props.title}</div> */}
              <button className="logout-btn" title="Log Out" onClick={logOutHandler}>
                <FaSignOutAlt/>
              </button>
            </div>
        </nav>
      </div>
    </header>
  )
}

export default Header;