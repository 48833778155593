import React, { useEffect, useState } from "react";
import DataBox from "./DataBox";
import {FaBullhorn, FaSearchDollar, FaUsers} from "react-icons/fa";
import { Doughnut, Bar } from 'react-chartjs-2';
import API from "./../../API";

const HomePanel = () => {
  const [statistic, setStatistic] = useState({})
  const productsSales = {
    labels: ["Salgina", "Cascella", "Trift", "Tamina"],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: [
          '#C09A18',
          '#cdae46',
          '#d9c274',
          '#e6d7a3',
        ],
        hoverBackgroundColor: [
          '#9a7b13',
          '#a48b38',
          '#ae9b5d',
          '#b8ac82',
        ],
        data: [statistic.salgina_sales, statistic.cascella_sales, statistic.trift_sales, statistic.tamina_sales]
      }
    ]
  }

  const productIncomes = {
    labels: ["Salgina", "Cascella", "Trift", "Tamina"],
    datasets: [
      {
        label: 'Income',
        backgroundColor: '#C09A18',
        data: [statistic.salgina_income, statistic.cascella_income, statistic.trift_income, statistic.tamina_income]
      }
    ]
  }

  useEffect(() => {
    API.getStatistic()
    .then(res => {
      console.log(res.data)
      setStatistic({
        clients: res.data.clients_num,
        sales: res.data.orders_num,
        income: res.data.total_sales,
        salgina_sales: res.data.sales[0].sales_share,
        cascella_sales: res.data.sales[1].sales_share,
        trift_sales: res.data.sales[2].sales_share,
        tamina_sales: res.data.sales[3].sales_share,
        salgina_income: res.data.sales[0].price_share,
        cascella_income: res.data.sales[1].price_share,
        trift_income: res.data.sales[2].price_share,
        tamina_income: res.data.sales[3].price_share,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }, [])

  return (
    <div className="container">
      <div className="home-panel">
        <div className="row" style={{justifyContent: "space-between"}}>
          <DataBox 
              title="ANZAHL KUNDEN"
              value={statistic.clients}
              children = {<FaUsers/>}
            />
            <DataBox 
              title="BESTELLUNGEN"
              value={statistic.sales}
              children = {<FaBullhorn/>}
            />
            <DataBox 
              title="UMSATZ TOTAL"
              value={statistic.income + " CHF"}
              children = {<FaSearchDollar/>}
            />
        </div>
        <div className="row" style={{justifyContent: "space-between"}}> 
          <div className="chart-wrap">
            <Doughnut
              data={productsSales}
              options={{
                title: {
                  display: true,
                  text: 'Umsatz nach Platte',
                  fontSize: 20
                },
                legend:{
                  display: true,
                  position: 'right'
                }
              }}
            />
          </div>
          <div className="bar-wrap">
            <Bar 
              data={productIncomes}
              options={{
                title:{
                  display: true,
                  text:'Einkommen',
                  fontSize: 20
                },
                legend:{
                  display: true,
                  position: 'right'
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePanel;