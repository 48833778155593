function formatDate(date) {

    var day = date.getDate();
    var monthIndex = date.getMonth() + 1 ;
    var year = date.getFullYear();
    var hours = date.getHours();
    var min = date.getMinutes();
  
    hours = hours < 10 ? '0'+hours : hours;
    min = min < 10 ? '0'+min : min;
  
    return day + '.' + monthIndex + '.' + year + ', ' + hours + ':' + min;
  }
  
  export {
      formatDate
  }