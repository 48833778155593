import axios from "axios";

const adminUrl = process.env.REACT_APP_ADMIN_URL;

// intercept each request
axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (401 === error.response.status) {
    window.location.replace(`/login`);
  } else {
    return Promise.reject(error.response.data.error);
  }
});

export default {
  adminUrl,
  login(loginData) {
    return axios.post(`/api/admin/auth/login`, loginData);
  },
  logOut() {
    return axios.post(`/api/admin/auth/logout`);
  },
  getClients() {
    return axios.get(`/api/admin/client`);
  },
  getSingleClient(id) {
    return axios.get(`/api/admin/client/${id}`);
  },
  createClient(newClient) {
    return axios.post(`/api/admin/client/`, newClient);
  },
  editClient(clientID, editedClient) {
    return axios.patch(`/api/admin/client/${clientID}`, editedClient);
  },
  createUser(newUser) {
    return axios.post(`/api/admin/user`, newUser);
  },
  editUser(userID, editedUser) {
    return axios.patch(`/api/admin/user/${userID}`, editedUser);
  },
  sendEmail(userID) {
    return axios.put(`/api/admin/user/${userID}/onboard`)
  },
  deleteUser(userID) {
    return axios.delete(`/api/admin/user/${userID}`)
  },
  getStatistic() {
    return axios.get(`/api/admin/statistic`)
  },
  deleteClient(clientID) {
    return axios.delete(`/api/admin/client/${clientID}`)
  },
}
