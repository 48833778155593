import React from 'react';

const ButtonToggleSideDrawer = (props) => {
    return (
        <button className='button_toggle' onClick={props.click}>
            <div className='button_toggle_line'></div>
            <div className='button_toggle_line'></div>
            <div className='button_toggle_line'></div>
        </button>
    )
};

export default ButtonToggleSideDrawer;